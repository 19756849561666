/**
 * ArchiveCard
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */
import React from "react"
import { useStaticQuery, Link, graphql } from "gatsby"
import Parse from "html-react-parser"
import * as styles from "./archive-card.module.scss"
import { getSrc } from "gatsby-plugin-image"
const ArchiveCard = ({ post, hiddenDatetime }) => {
  const { noImage } = useStaticQuery(
    graphql`
      query {
        noImage: file(relativePath: { eq: "no-image.png" }) {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
    `
  )
  var workDate = new Date(post.date)
  var postDate =
    workDate.getFullYear() +
    "年" +
    (workDate.getMonth() + 1) +
    "月" +
    workDate.getDate() +
    "日"
  const backgroundImageNoImage = getSrc(noImage?.childImageSharp)
  const backgroundImageEyecatch =
    post?._embedded["wp:featuredmedia"][0]?.media_details?.sizes?.medium_large
      ?.source_url
  return (
    <Link to={post.link} itemProp="url">
      <div className={"card " + styles.card}>
        <div className="card-image">
          <div className={styles.banner}>
            <div className={styles.banner__cover}></div>
            <div className={styles.banner__label}>
              {Parse(post.title.rendered)}
            </div>
            {/* <BackgroundImage
              Tag="div"
              className={"image is-16by9 " + styles.banner__image}
              {...(backgroundImageEyecatch
                ? backgroundImageEyecatch
                : backgroundImageNoImage)}
              preserveStackingContext
            ></BackgroundImage> */}
            <figure class="image is-16by9">
              <img
                src={
                  backgroundImageEyecatch
                    ? backgroundImageEyecatch
                    : backgroundImageNoImage
                }
              />
            </figure>
          </div>
        </div>
        <div className="card-content">
          {hiddenDatetime === true ? (
            ""
          ) : (
            <div className="has-text-grey	mb-5">
              <time datetime={post.datetime}>{postDate}</time>
            </div>
          )}
          <h2 className="title is-size-6 mb-3">
            <span itemProp="headline">{Parse(post.title.rendered)}</span>
          </h2>
          <div className="is-size-7" itemProp="description">
            {Parse(post.excerpt.rendered)}
          </div>
        </div>
      </div>
    </Link>
  )
}
export default ArchiveCard
