/**
 * NewsMedia
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */
import React from "react"
import Parse from "html-react-parser"
import * as styles from "./news-media.module.scss"
const NewsMedia = ({ post }) => {
  var workDate = new Date(post.date)
  var postDate =
    workDate.getFullYear() +
    "年" +
    (workDate.getMonth() + 1) +
    "月" +
    workDate.getDate() +
    "日"
  const categoryTag = () => {
    switch (post.categories[0]) {
      case 66:
        return <span class="tag is-success">イベント</span>
      case 72:
        return <span class="tag is-info">お知らせ</span>
      case 43:
        return <span class="tag is-danger">ニュースリリース</span>
      case 46:
        return <span class="tag is-warning">メディア</span>
      default:
        return
    }
  }
  const newTag = () => {
    let postDate = new Date(post.date)
    let nowDate = new Date()
    if ((nowDate - postDate) / 86400000 > 7) {
      return
    }
    return (
      <span class="is-size-7	has-text-weight-semibold has-text-danger-dark ml-1">
        新着!!
      </span>
    )
  }
  return (
    <article class="media">
      <div class="media-content">
        <div className={"content " + styles.box}>
          <div className={styles.meta__date}>
            <time datetime={post.datetime}>{postDate}</time>
          </div>
          <div className={styles.meta__category}>{categoryTag()}</div>
          <div className={styles.title}>
            <a href={post.link}>
              {Parse(post.title.rendered)}
              {newTag()}
            </a>
          </div>
        </div>
      </div>
    </article>
  )
}
export default NewsMedia
